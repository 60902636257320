import React from "react";
import { NavLink, NavNavLink } from "react-router-dom";
import "./Header/Header.css";

const HeaderBottom = () => {
  return (
    <>
      <div>
        <div className="content_padding-header">
          <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
              <div className="navbar-brand" href="#">
                <NavLink className="nav-NavLink" to="/">
                  <img src="images/unnamed.png" alt="" className="img-fluid" />
                </NavLink>
              </div>
              <div className="collapse navbar-collapse  justify-content-between align-items-center" id="collapsibleNavbar">
                <ul className="navbar-nav  nav_class  w-100 " style={{ justifyContent: "space-between" }}>
                  <li className="nav-item dropdown backcolor">
                    <NavLink className={`${window.location.pathname == "/" ? "dropdown-item active" : "dropdown-item"}`} to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      className={`${window.location.pathname == "/chat-with-astrologer" ? "dropdown-item active" : "dropdown-item"}`}
                      to="/chat-with-astrologer"
                    >
                      Chat with Astrologer
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      className={`${window.location.pathname == "/talk-to-astrologer" ? "dropdown-item active" : "dropdown-item"}`}
                      to="/talk-to-astrologer"
                    >
                      Talk to Astrologer
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink className={`${window.location.pathname == "/freekundli" ? "dropdown-item active" : "dropdown-item"}`} to="/freekundli">
                      {" "}
                      Free Kundli
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      className={`${window.location.pathname == "/horoscope/daily-horoscope" ? "dropdown-item active" : "dropdown-item"}`}
                      to="/horoscope/daily-horoscope"
                    >
                      Horoscopes
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <NavLink className={`${window.location.pathname == "/matchmaking" ? "dropdown-item active" : "dropdown-item"}`} to="/matchmaking">
                      Kundli Matching
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown moreNavBtn">
                    <div className={`${window.location.pathname == "#" ? "dropdown-item active" : "dropdown-item"}`}>More ￬</div>
                    <ul className="moreNavList">
                      <li className="nav-item dropdown">
                        <NavLink
                          className={`${window.location.pathname == "/astroshop" ? "dropdown-item active" : "dropdown-item"}`}
                          to="/astroshop"
                          style={{ margin: "4px 0px" }}
                        >
                          Astroshop
                        </NavLink>
                      </li>

                      <li className="nav-item dropdown">
                        <NavLink
                          className={`${window.location.pathname == "numerology" ? "dropdown-item active" : "dropdown-item"}`}
                          style={{ marginTop: "0px" }}
                          to="/numerology"
                        >
                          Numerology
                        </NavLink>
                      </li>

                      <li className="nav-item dropdown">
                        <NavLink
                          className={`${window.location.pathname == "panchang" ? "dropdown-item active" : "dropdown-item"}`}
                          to="/panchang"
                          style={{ margin: "4px 0px" }}
                        >
                          Panchang
                        </NavLink>
                      </li>
                      <li className="nav-item dropdown">
                        <NavLink
                          className={`${window.location.pathname == "/blog" ? "dropdown-item active" : "dropdown-item"}`}
                          to="/blog"
                          style={{ margin: "4px 0px" }}
                        >
                          Blog
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default HeaderBottom;
